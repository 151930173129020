import "bootstrap/dist/css/bootstrap.min.css";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import VueGoogleMaps from '@fawmi/vue-google-maps'
library.add(fas);
createApp(App)
.component('fa',FontAwesomeIcon)
.use(store)
.use(router)
.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAOZe_kwgfg12mP_c7D1oZYmFbyAagBpHs',
    },
    autobindAllEvents: true,
})
.mount('#app')
