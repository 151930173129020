<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
       <router-view/>
      </div>
    </div>
  </div>
  
</template>


<style>
  
  .toolbar{
    border-bottom-width: 1px;
    border-bottom-style: inset;
    border-bottom-color: #b5b8c8;
  }
  .bg-paracas-1{
    background-color:#07B2E4;
  }
  .text-paracas-1{
    color:#07B2E4;
  }
  .bg-paracas-2{
    background-color:#DAE407;
  }
  .text-paracas-2{
    color:#DAE407;
  }

  .btn-outline-paracas-1 { 
  color: #07B2E4; 
  background-color: #FFFFFF; 
  border-color: #07B2E4; 
} 
 
.btn-outline-paracas-1:hover, 
.btn-outline-paracas-1:focus, 
.btn-outline-paracas-1:active, 
.btn-outline-paracas-1.active, 
.open .dropdown-toggle.btn-outline-paracas-1 { 
  color: #07B2E4; 
  background-color: #FFFFFF; 
  border-color: #07B2E4; 
} 
 
.btn-outline-paracas-1:active, 
.btn-outline-paracas-1.active, 
.open .dropdown-toggle.btn-outline-paracas-1 { 
  background-image: none; 
} 
 
.btn-outline-paracas-1.disabled, 
.btn-outline-paracas-1[disabled], 
fieldset[disabled] .btn-outline-paracas-1, 
.btn-outline-paracas-1.disabled:hover, 
.btn-outline-paracas-1[disabled]:hover, 
fieldset[disabled] .btn-outline-paracas-1:hover, 
.btn-outline-paracas-1.disabled:focus, 
.btn-outline-paracas-1[disabled]:focus, 
fieldset[disabled] .btn-outline-paracas-1:focus, 
.btn-outline-paracas-1.disabled:active, 
.btn-outline-paracas-1[disabled]:active, 
fieldset[disabled] .btn-outline-paracas-1:active, 
.btn-outline-paracas-1.disabled.active, 
.btn-outline-paracas-1[disabled].active, 
fieldset[disabled] .btn-outline-paracas-1.active { 
  background-color: #FFFFFF; 
  border-color: #07B2E4; 
} 
 
.btn-outline-paracas-1 .badge { 
  color: #FFFFFF; 
  background-color: #07B2E4; 
}
</style>